
.zIndex10
  z-index: 10
.hc-thumbnail
  &:after
    opacity: 0
    transition: 0.3s ease all
  &:hover:after
    opacity: 0.8
@media (min-width: 600px)
  .q-dialog__inner--minimized > div
    max-width: unset
