// MISC
$transition-duration : 300ms

// PALETTE
$purple-1   : #F7F2FF
$purple-2   : #EEE5FE
$purple-3   : #C19CFC
$purple-4   : #6308F7
$purple-5   : #3C0594
$blue-1     : #F2FCFF
$blue-2     : #E5F8FF
$blue-3     : #66D6FF
$blue-4     : #00A7E5
$blue-5     : #007099
$grey-1     : #F7F7FC
$grey-2     : #EFF0F6
$grey-3     : #D9DBE9
$grey-4     : #A0A3BD
$grey-5     : #6E7191
$grey-6     : #4E4B66
$grey-7     : #262338
$green-1    : #F2FFF9
$green-2    : #E5FFF2
$green-3    : #00FE80
$green-4    : #00CC67
$green-5    : #00994D
$red-1      : #FFF2F9
$red-2      : #FFE5F2
$red-3      : #FF84B0
$red-4      : #E40173
$red-5      : #98014C
$gold-1     : #FEFAF1
$gold-2     : #FBF3E2
$gold-3     : #F3D9A5
$gold-4     : #E2A21F
$gold-5     : #5A400C

// OTHERS
$tiktok     : #24292E
$threads    : #24292E
$youtube    : #EF3232
$twitter    : #00AEF2
$linkedin   : #008BCA
$facebook   : #2362B7
$instagram  : #AD20BF
$website    : #DDDDDD
$white      : #FFFFFF
$black      : #000000
$vdc        : #0086FF

// NORMAL
$primary    : $purple-4
$secondary  : $blue-4
$accent     : $purple-3
$dark       : $grey-7
$positive   : $green-4
$negative   : $red-4
$info       : $blue-3
$warning    : $gold-4
$background : $grey-1
// Theme colors lighten by 95%
// https://quasar.dev/quasar-utils/color-utils
$info-light     : #f7fdff
$primary-light  : #f7f3ff
$warning-light  : #fefaf4
$negative-light : #fef2f8
$positive-light : #f2fcf7

// DARKMODE
$primary-dark    : $purple-3
$secondary-dark  : $blue-3
$accent-dark     : $purple-2
$dark-dark       : $grey-1
$positive-dark   : $green-3
$negative-dark   : $red-3
$info-dark       : $blue-2
$warning-dark    : $gold-3
$background-dark : #12171B
