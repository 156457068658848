@import '~src/css/quasar.variables.sass', 'quasar/src/css/variables.sass'

.marker
  background-size: cover
  width: 50px
  height: 50px
  border-radius: 50%
  cursor: pointer

.mapboxgl-popup-content
  background-color: $accent
  color: $dark
