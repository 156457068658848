
.avoid-break
  page-break-inside: avoid

@page
  width: 21cm
  height: 29.7cm
  margin: 27mm 16mm 27mm 16mm

@media print
  body
    width: 21cm
    height: 29.7cm
    /* margin: 30mm 45mm 30mm 45mm */
    margin: 0mm
    /* change the margins as you want them to be. */
